export const AA = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Alumni Assosciation</h2>
          {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
        </div>
        <div className="row ">
          {/* {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className="col-md-3 col-sm-6 col-lg-4 team"
                >
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"} */}
          <div className="col text-center d-flex  justify-content-sm-center">
            <img
              src="img/AAE.jpeg"
              className="img-responsive mx-auto "
              alt=""
            />
          </div>{" "}
        </div>
      </div>
    </div>
  );
};
